<template>
  <div>
    <div class="pnf-backdrop">
      <img class="auth-logo" src="@/assets/FOTH_logotyp_Roboto.png" alt="Focus on the Harvest">
      <div style="margin: 50px 0;">
        <h1>404 Page not found</h1>
        <p>The page you're looking for could not be found. Make sure the entered URL is correct, or return to the <a href="/" class="text-hoverable" style="text-decoration: underline !important">homepage</a>.</p>
        <p>If you think this is an error, contact jonathan.runeke@hotmail.com.</p>
      </div>
    </div>
    <DevyneLogo style=""/>
  </div>
</template>

<script>
import DevyneLogo from '../assets/devyne_logo_2.svg'

export default {
  components: {
    DevyneLogo
  }
}
</script>

<style>
.pnf-backdrop {
  max-width: 1000px;
  width: 90%;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.075);
}

.auth-logo {
  min-width: none;
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
}
</style>